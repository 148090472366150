const custodyOrganization = {
    'custody.custodyOrganization.custodyUserProduct': 'Custody用户',
    'custody.custodyOrganization.custodyUserProduct.old': 'Custody用户(旧)',
    'custody.custodyOrganization.custodyUserProduct.detail': 'Custody用户详情',

    'custody.custodyOrganization.env.sandbox': '沙盒环境',
    'custody.custodyOrganization.env.product': '生产环境',
    'custody.custodyOrganization.createButton.createOrg': '添加机构',
    'custody.custodyOrganization.search.placeholder': '机构名搜索',
    'custody.custodyOrganization.table.head.index': '机构编号',
    'custody.custodyOrganization.table.head.org': '机构名',
    'custody.custodyOrganization.table.head.status': '机构状态',
    'custody.custodyOrganization.table.head.created_time': '创建时间',
    'custody.custodyOrganization.table.head.asset': '资产',
    'custody.custodyOrganization.table.head.configuration': '配置',
    'custody.custodyOrganization.table.head.actions': '操作',
    'custody.custodyOrganization.table.body.view': '查看',
    'custody.custodyOrganization.table.body.disable': '冻结',
    'custody.custodyOrganization.table.body.cancel': '取消',
    'custody.custodyOrganization.table.body.churnSaving': '保号',
    'custody.custodyOrganization.table.body.stopChurnSaving': '重新激活',
    'custody.custodyOrganization.table.body.laterPayment': '欠费',
    'custody.custodyOrganization.table.body.Paid': '正常付费',

    'custody.custodyOrganization.table.body.reinvite': '重新邀请',
    'custody.custodyOrganization.table.body.enable': '解冻',
    'custody.custodyOrganization.table.body.status.active': '已激活',
    'custody.custodyOrganization.table.body.status.pending': '待激活',
    'custody.custodyOrganization.table.body.status.frozen': '已冻结',
    'custody.custodyOrganization.table.body.status.disabled': '不可用',
    'custody.custodyOrganization.table.body.status.available': '可用',
    'custody.custodyOrganization.table.body.sure.disable': '确定冻结该用户么？',
    'custody.custodyOrganization.table.body.sure.enable': '确定解冻该用户么？',
    'custody.custodyOrganization.table.body.hint.disable': '注：冻结用户将限制该用户所有权限，请确保当前用户不再使用',
    'custody.custodyOrganization.table.body.hint.enable':
        '注：解冻用户将恢复该用户的一切权限，请确保该用户账户处于安全状态',

    'custody.custodyOrganization.modal.createParams.Organization': '机构名',
    'custody.custodyOrganization.modal.createParams.AdminUserName': '管理员名称',
    'custody.custodyOrganization.modal.createParams.AdminEmail': '管理员邮箱账号',
    'custody.custodyOrganization.modal.createParams.AdminMobile': '管理员手机号',
    'custody.custodyOrganization.modal.createParams.Plan': '配置策略(可在配置中修改)',
    'custody.custodyOrganization.modal.createParams.OrgType': 'Org类型',
    'custody.custodyOrganization.modal.createParams.useIndependentRemoteWallet': '是否独立资金池',
    'custody.custodyOrganization.modal.createParams.Coins': '币种（选填,英文逗号分隔）',
    'custody.custodyOrganization.modal.createParams.Warn': '用户需要在邮件链接中激活账户',
    'custody.custodyOrganization.modal.createParams.Invite': '邀请',
    'custody.custodyOrganization.modal.configuration.RiskControl': '风控策略',
    'custody.custodyOrganization.modal.configuration.SpeedLimits': '限额限速',
    'custody.custodyOrganization.modal.configuration.Rebalancing': '自动资金划转',
    'custody.custodyOrganization.modal.configuration.LowBalanceAlert': '资金低额报警',
    'custody.custodyOrganization.modal.configuration.Title': '配置信息',
    'custody.custodyOrganization.modal.configuration.Plan': '配置策略',
    'custody.custodyOrganization.modal.configuration.AccountExpiry': '账户到期时间',
    'custody.custodyOrganization.modal.configuration.BasicService': '基础交易服务',
    'custody.custodyOrganization.modal.configuration.parameterDetail': '参数详情(已用/上限) ',
    'custody.custodyOrganization.modal.configuration.tradingVolume': '交易量',
    'custody.custodyOrganization.modal.configuration.SupportedChains': '支持公链数',
    'custody.custodyOrganization.modal.configuration.EnterpriseUserAccounts': '企业用户数',
    'custody.custodyOrganization.modal.configuration.ChildWallets': '子账户（钱包）数',
    'custody.custodyOrganization.modal.reserveAccountType.twelve': '12个月',
    'custody.custodyOrganization.modal.reserveAccountType.six': '6个月',
    // 添加机构-表单提示
    'custody.custodyOrganization.input.Organization.warn': '机构名不能为空',
    'custody.custodyOrganization.input.AdminUserName.warn': '管理员不能为空',
    'custody.custodyOrganization.input.AdminEmail.warn': '邮件不能为空',
    'custody.custodyOrganization.input.useIndependentRemoteWallet.warn': '请选择是否独立资金池',
    'custody.custodyOrganization.input.useIndependentRemoteWallet.tip': '独立资金池客户需要联系技术配置BC风控相关信息',

    'custody.custodyOrganization.input.Organization.placeholder': '英文简称，例如: Cobo',
    'custody.custodyOrganization.input.AdminUserName.placeholder': '用户名，例如: Heaven',
    'custody.custodyOrganization.input.AdminEmail.placeholder': '用户Email(使用邮箱登录)',

    'custody.custodyOrganization.modal.configuration.SystemPerformance': '系统性能指标',
    'custody.custodyOrganization.modal.configuration.CallsPerSecond': '每秒接口限额',
    'custody.custodyOrganization.modal.configuration.CallsPerMonth': '每月接口限额',
    'custody.custodyOrganization.modal.configuration.TotalAddress': '可创建地址数',
    'custody.custodyOrganization.modal.configuration.transactionLimit': '月交易上限额度',
    'custody.custodyOrganization.modal.configuration.bips': '交易量BIPS',
    'custody.custodyOrganization.modal.configuration.aucBips': 'AUC BIPS',
    'custody.custodyOrganization.modal.configuration.Coins': '币种(英文逗号分隔)',
    'custody.custodyOrganization.modal.configuration.Others': '其他配置(特殊用户)',
    'custody.custodyOrganization.modal.configuration.OthersHint': '信誉较高用户',
    'custody.custodyOrganization.modal.configuration.TransactionDiscount': '交易费折扣',
    'custody.custodyOrganization.modal.configuration.CoboFeeDiscount': '万5 Cobo fee 折扣',
    'custody.custodyOrganization.modal.configuration.CoboManualFeeDiscount': '站外提现交易折扣',
    'custody.custodyOrganization.modal.configuration.InnerCoboFeeDiscount': 'Loop交易费折扣',
    'custody.custodyOrganization.modal.configuration.Remark': '备注信息',
    'custody.custodyOrganization.modal.configuration.Used': '已使用',
    'custody.custodyOrganization.modal.configuration.overage': '超量',
    'custody.custodyOrganization.modal.configuration.Reset': '重置',
    'custody.custodyOrganization.modal.configuration.TransactionConfiguration': '交易配置',
    'custody.custodyOrganization.modal.configuration.churnSavingDate': '保号时间',
    'custody.custodyOrganization.modal.configuration.withdrawalsNumberMonth': '每月提现笔数',
    'custody.custodyOrganization.modal.configuration.setInsurancePackage': '设置保号套餐',
    'custody.custodyOrganization.modal.configuration.suspension': '确认欠费暂停',
    'custody.custodyOrganization.modal.configuration.reactivate': '确认重新激活',
    'custody.custodyOrganization.modal.configuration.normallyPayment': '确认正常付费？',

    'custody.custodyOrganization.modal.configuration.businessType': '客户业务类型',
    'custody.custodyOrganization.modal.configuration.level': '客户等级',
    'custody.custodyOrganization.modal.configuration.manager': '客户经理',
    'custody.custodyOrganization.modal.configuration.productType': '客户购买产品类型',
    'custody.custodyOrganization.modal.configuration.serviceStartTime': '客户开始计费时间',
    'custody.custodyOrganization.modal.configuration.serviceEndTime': '客户结束计费时间',
    'custody.custodyOrganization.modal.configuration.workflow': '审批工作流',
    'custody.custodyOrganization.modal.configuration.custodyv2': 'Portal 迁移状态',
    'custody.custodyOrganization.modal.configuration.turnon': '开启',
    'custody.custodyOrganization.modal.configuration.turnoff': '关闭',
    'custody.custodyOrganization.modal.configuration.remoteWalletId': '资金池ID',
    'custody.custodyOrganization.modal.configuration.remoteWalletId_placeholder': '资金池ID',
    'custody.custodyOrganization.modal.configuration.isSingaporeOrg': '是否是新加坡org',
    'custody.custodyOrganization.modal.configuration.isSingaporeOrg_true': '是',
    'custody.custodyOrganization.modal.configuration.isSingaporeOrg_false': '否',

    'custody.custodyOrganization.modal.invite.title': '邀请链接',
    'custody.custodyOrganization.modal.invite.hint': '以下链接已发送至注册邮箱',
    'custody.custodyOrganization.modal.asset.title': '总资产',

    'custody.custodyOrganization.formItem.label.orgVersion': 'Org版本',
    'custody.custodyOrganization.formItem.select.normal_org': 'Custody通用版',
    'custody.custodyOrganization.formItem.select.singapore_org': 'Custody新加坡版',
    'custody.custodyOrganization.formItem.label.enabled': '是否开通',
    'custody.custodyOrganization.formItem.label.openingTime': '账户开通时间',
    'custody.custodyOrganization.formItem.label.valueAddedServices': '增值服务',
    'custody.custodyOrganization.formItem.label.projectName': '项目名称',
    'custody.custodyOrganization.formItem.label.startTime': '起始时间',
    'custody.custodyOrganization.formItem.label.endTime': '终止时间',

    'custody.custodyOrganization.action.button.modifyParameters': '修改参数',
    'custody.custodyOrganization.action.button.revertDefaultConfig': '恢复默认配置',

    'custody.custodyOrganization.modal.loop': '联盟信息',
    'custody.custodyOrganization.modal.confirm': '确认',
    'custody.custodyOrganization.modal.loop.confirmAssociated': '确认关联当前已选择Org',
    'custody.custodyOrganization.modal.loop.confirmRemove': '确认移除当前关联Org',
    'custody.custodyOrganization.modal.remove': '移除',
    'custody.custodyOrganization.modal.associatedOrg': '已关联Org',
    'custody.custodyOrganization.modal.notAssociatedOrg': '未关联Org',
    'custody.custodyOrganization.modal.associated': '关联',
    'custody.custodyOrganization.modal.endOfMonthCancel': '本月结束取消',

    'custody.custodyOrganization.tabTitle.fullCustody': '全托管-收发币',
    'custody.custodyOrganization.tabTitle.fullCustodyWeb3': '全托管-web3',
    'custody.custodyOrganization.tabTitle.coManagedSend': 'MPC-收发币',
    'custody.custodyOrganization.tabTitle.coManagedWeb3': ' MPC-web3',
    'custody.custodyOrganization.tabTitle.fullCustodyNew': '全托管-收发币（新）',

    'custody.custodyOrganization.button.packagemodification': '套餐修改',
    'custody.custodyOrganization.effectiveTime': '生效时间',
    'custody.custodyOrganization.originalPackage': '原套餐',
    'custody.custodyOrganization.changeTo': '更改为',
    'custody.custodyOrganization.noLimit': '无上限',
};
export default custodyOrganization;
